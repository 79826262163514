@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,500;0,700;0,900;1,800&display=swap");

body {
  margin: 0;
  font-family: Raleway;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-top: solid 15px #fff;
  border-left: solid 15px #fff;
  border-bottom: solid 15px #ffffff;
}

code {
  font-family: Raleway;
}

html::-webkit-scrollbar {
  width: 15px;
  height: 5px;
}

html::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #fca311, #fca311);
  border-radius: 20px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

html::-webkit-scrollbar-track {
  background-color: white;
}
