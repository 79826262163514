.date h2 {
  display: inline;
  margin-left: 20px;
  margin: 0;
  padding-bottom: 0;
}

.containerTwo {
  background-color: white;
}

span {
  margin-right: 20px;
}

.track h4 {
  margin-top: 40px;
  margin-bottom: 10px;
}

.artists {
  margin-top: 20px;
  margin-bottom: 45px;
}

hr {
  width: 50px;
  border-color: #fca311;
}

.track {
  margin-bottom: 80px;
  z-index: 10;
}

.margin {
  margin-left: 25px;
}

.soundCloudPlayer {
  background-color: red;
  height: 400px;
  color: yellow;
}

.playerWrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  background-color: dimgray !important;
  border-radius: 8px !important;
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
  background-color: dimgray !important;
  border-radius: 8px !important;
}

.artists {
  font-size: 1.5rem;
}

@media only screen and (max-width: 320px) {
  .reactPlayer {
    width: 80%;
    margin: 0 auto;
  }
}
