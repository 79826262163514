@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,500;0,700;0,900;1,800&display=swap);
body {
  margin: 0;
  font-family: Raleway;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-top: solid 15px #fff;
  border-left: solid 15px #fff;
  border-bottom: solid 15px #ffffff;
}

code {
  font-family: Raleway;
}

html::-webkit-scrollbar {
  width: 15px;
  height: 5px;
}

html::-webkit-scrollbar-thumb {
  background: -webkit-gradient(linear, left top, left bottom, from(#fca311), to(#fca311));
  background: linear-gradient(180deg, #fca311, #fca311);
  border-radius: 20px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

html::-webkit-scrollbar-track {
  background-color: white;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
  color: #fff;
  padding-top: 150px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.App-header-playing {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
  color: #fff;
  padding-top: 150px;
  background: url(https://media.giphy.com/media/r0XKA03tGOrGU/giphy.gif)
    no-repeat center center fixed;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  background-size: cover;
}

.App-headerTwo {
  background-color: red;
}

.Applink {
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #fff;
  padding-bottom: 2px;
}

.Applink:hover {
  color: orange;
  text-decoration: none;
  border-bottom: 3px solid #fff;
  padding-bottom: 2px;
}

.headerContainer {
  position: relative;
  z-index: 1;
  white-space: nowrap;
  color: #fca311;
  overflow: hidden;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 10rem;
  background-color: #000;
  border-left: 10vw solid black;
  border-right: 10vw solid black;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

.headerContainerParty {
  display: none;
}

.fixedButton {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 70px;
  height: auto;
  padding: 25px;
  border: none;
  z-index: 99;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  text-align: center;
}

.anim {
  display: inline-block;
  -webkit-animation-name: translate;
  animation-name: translate;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  height: 100px;
}

.anim h1 {
  font-weight: 200;
  font-size: 10rem;
  line-height: 0.5;
}

@-webkit-keyframes translate {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
@keyframes translate {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

.logoHeader {
  background: linear-gradient(-135deg, #fff 5px, transparent 0) 0 5px,
    linear-gradient(135deg, #fff 5px, #000 0) 0 5px;
  background-color: #fff;
  background-position: left bottom;
  background-repeat: repeat-x;
  background-size: 10px 10px;
}

.logoNew img {
  width: 100px;
  height: 100px;
  padding: 10px;
}

.partyModeButton {
  border: 0;
  background: none;
  text-decoration: none;
  color: black;
  font-family: "Raleway", cursive;
  font-weight: 900;
  font-size: 4rem;
  text-align: center;
  outline: none;
  cursor: pointer;
}

.partymode-button button {
  background-color: none;
  background: none;
  background: none;
  border: 0;
  color: white;
  text-decoration: underline;
  font-size: 1.1rem;
  outline: none;
  cursor: pointer;
}

.record-container {
  display: inline-block;
  overflow: hidden;
  width: 400px;
  height: 300px;
  border-radius: 10px;
  box-shadow: 0 6px #99907e;
  background: #b5ac9a;
}
.record {
  position: fixed;
  margin: 19px auto;
  width: 262px;
  height: 262px;
  border-radius: 50%;
  background: linear-gradient(
        30deg,
        transparent 40%,
        rgba(42, 41, 40, 0.85) 40%
      )
      no-repeat 100% 0,
    linear-gradient(60deg, rgba(42, 41, 40, 0.85) 60%, transparent 60%)
      no-repeat 0 100%,
    repeating-radial-gradient(#2a2928, #2a2928 4px, #ada9a0 5px, #2a2928 6px);
  background-size: 50% 100%, 100% 50%, 100% 100%;
}
.record:after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -35px;
  border: solid 1px #d9a388;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #da5b33, inset 0 0 0 27px #da5b33;
  background: #b5ac9a;
  content: "";
}

.logo {
  display: none;
  width: 150px;
  height: 150px;
  background-color: #fff;
  position: fixed;
  top: -1px;
  left: 6%;
  border-radius: 0 0 55px 10px;
  border-right: 3px solid lightgray;
  border-bottom: 4px solid lightgray;
  z-index: 2;
  -webkit-animation: slide-in-top 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.logo h1 {
  font-size: 2.5rem;
  color: #000;
}

h1.playing {
  font-size: 6rem;
  color: #000;
  margin-top: 3%;
  padding-top: 0;
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.container {
  position: relative;
  z-index: 0;
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
}

.player-container {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  margin-top: -100px;
}

.pulse {
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 30rem;
}

@-webkit-keyframes pulse {
  25% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes pulse {
  25% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OUuhs.ttf)
      format("truetype");
}
@font-face {
  font-family: "Pacifico";
  font-style: normal;
  font-weight: 400;
  src: local("Pacifico Regular"), local("Pacifico-Regular"),
    url(https://fonts.gstatic.com/s/pacifico/v16/FwZY7-Qmy14u9lezJ-6H6Mw.ttf)
      format("truetype");
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
h1 {
  font-family: "Raleway", cursive;
  font-weight: 400;
  font-size: 3em;
  text-align: center;
}
.menu a {
  text-decoration: none;
  color: black;
  font-family: "Raleway", cursive;
  font-weight: 900;
  font-size: 4rem;
  text-align: center;
}
.outer-menu {
  position: fixed;
  top: 11vh;
  left: 0;
  z-index: 2;
}
.outer-menu .checkbox-toggle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  width: 60px;
  height: 60px;
  opacity: 0;
}
.outer-menu .checkbox-toggle:checked + .hamburger > div {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.outer-menu .checkbox-toggle:checked + .hamburger > div:before,
.outer-menu .checkbox-toggle:checked + .hamburger > div:after {
  top: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.outer-menu .checkbox-toggle:checked + .hamburger > div:after {
  opacity: 0;
}
.outer-menu .checkbox-toggle:checked ~ .menu {
  pointer-events: auto;
  visibility: visible;
}
.outer-menu .checkbox-toggle:checked ~ .menu > div {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 0.75s;
  transition-duration: 0.75s;
}
.outer-menu .checkbox-toggle:checked ~ .menu > div > div {
  opacity: 1;
  -webkit-transition: opacity 0.4s ease 0.4s;
  transition: opacity 0.4s ease 0.4s;
}
.outer-menu .checkbox-toggle:hover + .hamburger {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}
.outer-menu .checkbox-toggle:checked:hover + .hamburger > div {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.outer-menu .hamburger {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 40px;
  height: 40px;
  padding: 0.2em 0.5em;
  background: #fca311;
  border-radius: 0 0.12em 0.12em 0;
  cursor: pointer;
  -webkit-transition: box-shadow 0.4s ease;
  transition: box-shadow 0.4s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.outer-menu .hamburger > div {
  position: relative;
  flex: none;
  width: 100%;
  height: 2px;
  background: #fefefe;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.outer-menu .hamburger > div:before,
.outer-menu .hamburger > div:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 0;
  width: 100%;
  height: 2px;
  background: inherit;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.outer-menu .hamburger > div:after {
  top: 10px;
}
.outer-menu .menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  visibility: hidden;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.outer-menu .menu > div {
  width: 200vw;
  height: 200vw;
  color: #fefefe;
  background: #fca311;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  flex: none;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.outer-menu .menu > div > div {
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
  opacity: 0;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  overflow-y: auto;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.outer-menu .menu > div > div > ul {
  list-style: none;
  padding: 0 1em;
  margin: 0;
  display: block;
  max-height: 100vh;
}
.outer-menu .menu > div > div > ul > li {
  padding: 0;
  margin: 1em;
  font-size: 24px;
  display: block;
}
.outer-menu .menu > div > div > ul > li > a {
  position: relative;
  display: inline;
  cursor: pointer;
  -webkit-transition: color 0.4s ease;
  transition: color 0.4s ease;
}
.outer-menu .menu > div > div > ul > li > a:hover {
  color: #e5e5e5;
}
.outer-menu .menu > div > div > ul > li > a:hover:after {
  width: 100%;
}
.outer-menu .menu > div > div > ul > li > a:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: -0.15em;
  left: 0;
  width: 0;
  height: 2px;
  background: #e5e5e5;
  -webkit-transition: width 0.4s ease;
  transition: width 0.4s ease;
}

.circle {
  position: fixed;
  border-radius: 50%;
  background: white;
  -webkit-animation: ripple 15s infinite;
          animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
}

.small {
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
}

.medium {
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large {
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge {
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

.xxlarge {
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.shade1 {
  opacity: 0.2;
}
.shade2 {
  opacity: 0.5;
}

.shade3 {
  opacity: 0.7;
}

.shade4 {
  opacity: 0.8;
}

.shade5 {
  opacity: 0.9;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }

  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }

  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

@media only screen and (max-width: 800px) {
  body {
    border: 0;
  }
  .ripples {
    display: none;
  }
  .logo {
    width: 75px;
    height: 70px;
    border-radius: 0 0 35px 10px;
  }
  .logo h1 {
    font-size: 1.2rem;
  }
  h1.playing {
    font-size: 3rem;
  }
  .logo.playing {
    background-color: #fca311;
  }
  .App-header,
  .App-header-playing {
    font-size: 1.5rem;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 80px;
  }
  .partymode-button {
    display: none;
  }
  .anim h1 {
    font-size: 7rem;
  }
  .player-container {
    margin-top: -60px;
  }
  .App-header {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .headerContainer {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

@media only screen and (max-width: 500px) {
  .menu a {
    font-size: 2rem;
  }
  .partyModeButton {
    font-size: 2rem;
    display: none;
  }
}

@media only screen and (max-width: 370px) {
  .reactPlayer {
    width: 68%;
    height: 100%;
    margin: 0 auto;
  }
}

.track_date__2H6x6 h2 {
  display: inline;
  margin-left: 20px;
  margin: 0;
  padding-bottom: 0;
}

.track_containerTwo__xW61e {
  background-color: white;
}

span {
  margin-right: 20px;
}

.track_track__1hCHa h4 {
  margin-top: 40px;
  margin-bottom: 10px;
}

.track_artists__3nQiX {
  margin-top: 20px;
  margin-bottom: 45px;
}

hr {
  width: 50px;
  border-color: #fca311;
}

.track_track__1hCHa {
  margin-bottom: 80px;
  z-index: 10;
}

.track_margin__11NH1 {
  margin-left: 25px;
}

.track_soundCloudPlayer__2_LJ6 {
  background-color: red;
  height: 400px;
  color: yellow;
}

.track_playerWrapper__2NbcD {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  background-color: dimgray !important;
  border-radius: 8px !important;
}

.track_reactPlayer__1mxpR {
  position: absolute;
  top: 0;
  left: 0;
  background-color: dimgray !important;
  border-radius: 8px !important;
}

.track_artists__3nQiX {
  font-size: 1.5rem;
}

@media only screen and (max-width: 320px) {
  .track_reactPlayer__1mxpR {
    width: 80%;
    margin: 0 auto;
  }
}

